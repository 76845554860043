import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState } from 'react';
import { Dialog } from '@headlessui/react'
import Footer from './components/Footer'
import ChallengeRules from './components/rules'
import './index.css'
import logo from './assets/asset-ch.svg'
import './landing.css'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


const container = document.getElementById('app');
const root = createRoot(container);

const navigation = [
  { name: 'Getting Started', href: '#getting-started', targetBlank: false},
  { name: 'Resources', href: 'https://docs.challengethemap.com/start-here/project-description', targetBlank: true },
]


function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
    <div className="isolate bg-white">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 overflow-hidden blur-3xl sm:top-[-20rem]">
      </div>
      <div className="px-6 pt-6 lg:px-8">
        <div>
          <nav className="flex h-9 items-center justify-between" aria-label="Global">
            <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
              <a href="#" className="-m-1.5 p-0">
                <span className="sr-only">Challenge the Map</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Open main menu</span>
              </button>
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.href} target={item.targetBlank ? '_blank': '_self'} href={item.href} className="font-semibold text-gray-900 hover:text-gray-900">
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
              <div className="flex h-9 items-center justify-between">
                <div className="flex">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Challenge The Map</span>
                    <img className="h-16 w-16" src={logo} alt="" />
                  </a>
                </div>
                <div className="flex">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </div>
      <main>
        <div className="hero-g relative px-6 lg:px-8 mt-4">
          <div className="mx-auto max-w-3xl pt-0 pb-32 sm:pt-8 sm:pb-40">
            <div className="py-46">
              <div className="">
                <h1 className="mb-16 text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                  Challenge the Map
                </h1>
                <p className="mt-8 text-3xl leading-8 sm:text-center">
                  Our project to help close the Digital Divide once and for all.
                </p>
                <div className="mt-8 flex gap-x-4 sm:justify-center">
                  <a
                    href="https://docs.challengethemap.com"
                    target="_blank"
                    className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-red-600 hover:ring-red-600"
                  >
                    Start the Challenge
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChallengeRules />
      </main>
    </div>
    <Footer />
    </>
  )
}

root.render(<Landing />);
