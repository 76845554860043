
const faqs = [
  {
    question: 'The Challenge Basics',
    answer:
      'To answer this challenge, your team will create Open Source Software , OpenData, or both that can help collect, transmit, encode, or study data submitted to the FCC\' Broadband Data Collection plan.',
  },
  {
    question: 'Write the code, Post the data, or both',
    answer:
      'To be considered, you will host your contributions on one of the majoe VCS providers we support: GitLab, GitHub, or BitBucket. We will consider each public repository a separate solution.',
  },
  {
    question: 'Submit your answer(s)',
    answer:
      'You make submissions by adding the `challengethemap` label or tag to your Open Source project. Refer to the VCS provider\'s documention to learn how to add labels/tags to Public code repositories, in general.',
  },
  {
    question: 'When does it start?',
    answer:
      'The challenge starts February 2023 and lasts until Febraury 29, 2024 AoE.',
  },
  {
    question: 'What is it all for?',
    answer:
      'The U.S. Federal Communication Commission (FCC) released digital maps displaying every location where broadband Internet is installable. Your task is to write software that can help collect, transmit, encode, or study data submitted to the FCC\' Broadband Data Collection plan.',
  },
  {
    question: 'Our role in this challenge',
    answer:
      'We\'re the people over at civichacker.com. We are participating by writing software that we think will aid crowdsourced efforts to assist the FCC in Broadband Data Collection. Our answers to the challenge will appear on this site.',
  }
]

export default function ChallengeRules() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 id="getting-started" className="text-3xl font-bold tracking-tight text-gray-900">Getting Started</h2>
            <p className="mt-4 text-lg text-gray-500">
              Can’t find the answer you’re looking for? Reach out to {' '}
              <a href="mailto:bdc@civichacker.com" className="font-medium text-indigo-600 hover:text-indigo-500">
                support
              </a>
              .
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
